import React from "react";
import styled from "styled-components";

const StyledTypeButton = styled.button`
  height: 24px;
  min-width: 80px;
  font-size: 12px;
  letter-spacing: 1px;
  /* background-color: #ebaa8e; */
  background-color: ${(props) => (props.isActive ? "white" : "#0F1616")};
  border-radius: 7px;
  color: ${(props) => (props.isActive ? "black" : "#828282")};
  z-index: 10;
  padding: 0 8px;
`;

function TypeButton({ ...props }) {
  return (
    <>
      <StyledTypeButton
        onClick={props.setIconsOrColors}
        isActive={props.currentButton === props.keyValue}
        btnindex={props.keyValue}
        length={props.length}
      >
        {props.typeName}
      </StyledTypeButton>
    </>
  );
}

export default TypeButton;
