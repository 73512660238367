const environment = window._conf || {
  ENV: "dev",
  // WASM_BUILD_URL: 'https://cdn-1.glamar.io/js/wasm/v0.1.42',
  // ASSET_URL: 'https://cdn-1.glamar.io/js/assets/v1.0.20',
  // SNELLO_URL: 'https://api.glamar.io/frieza',
  // MAKEUP_ASSET_URL: 'https://cdn-1.glamar.io/glamar_assets/web'

  // Development
  WASM_BUILD_URL: "https://cdn-1.glamaz0.de/js/wasm/v0.1.42",
  ASSET_URL:
    "https://cdn.pixelbin.io/v2/glamar-fynd-835885/ajIVXx/original/assets/website/",
  SNELLO_URL: "https://api.glamarz0.de/frieza",
  MAKEUP_ASSET_URL: "https://cdn-1.glamarz0.de/glamar_assets/web",
  fyndStyleLocal: "https://api.glamarz0.de/frieza",
  fyndStylez0: "https://fyndstyle-backend.glamarz0.de/v2",
  sdkUrlInternal: "https://fyndstyleweb.glamarz0.de/internal/index.html",
  sdkUrlRelease: "https://fyndstyleweb.glamarz0.de/release/index.html",
  sdkUrlOuter: "https://fyndstyleweb.glamarz0.de/",
};

export default environment;
