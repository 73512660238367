import React from "react";
import styled, { keyframes } from "styled-components";

const rotateCircle = keyframes`
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
`;

const iconLineTip = keyframes`
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
`;

const iconLineLong = keyframes`
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
`;

const AlertPopupContainer = styled.div`
  text-align: center;
`;

const SuccessCheckmark = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
  transform: scale(0.7);
`;

const CheckIcon = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;
  overflow: hidden;
`;

const LineTip = styled.span`
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;

  &.line-tip {
    top: 46px;
    left: 14px;
    width: 25px;
    transform: rotate(45deg);
    animation: ${iconLineTip} 0.5s;
  }
`;

const LineLong = styled.span`
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;

  &.line-long {
    top: 38px;
    right: 8px;
    width: 47px;
    transform: rotate(-45deg);
    animation: ${iconLineLong} 0.5s;
  }
`;

const IconCircle = styled.div`
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
`;

const IconFix = styled.div`
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
`;

const AlertPopupTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: #4caf50;
  // margin-top: 15px;
  margin-bottom: 15px;
  z-index: 2;
  position: relative;
`;

const SuccessIcon = () => {
  return (
    <AlertPopupContainer>
      <SuccessCheckmark>
        <CheckIcon>
          <LineTip className="line-tip"></LineTip>
          <LineLong className="line-long"></LineLong>
          <IconCircle></IconCircle>
          <IconFix></IconFix>
        </CheckIcon>
      </SuccessCheckmark>

      <AlertPopupTitle>Success!</AlertPopupTitle>
    </AlertPopupContainer>
  );
};

export default SuccessIcon;
