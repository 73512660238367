import styled from "styled-components";
import React from "react";
import hairIcon from "../assets/hair-icon.png";

const IconButtonContainer = styled.div`
  position: relative;
  height: 64px;
  width: 64px;
  border-radius: 64px;
  background-size: cover;
  background-color: ${({ color }) => color || "transparent"};
  background-image: ${({ showImage }) =>
    showImage ? `url(${hairIcon})` : "none"};
  background-blend-mode: multiply;

  @media (max-width: 768px) {
    height: 40px;
    width: 40px;
    border-radius: 40px;
  }
`;
const IconImage = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 64px;
  @media (max-width: 768px) {
    height: 40px;
    width: 40px;
    border-radius: 40px;
  }
`;

export default function IconButton({ imgUrl, color, isHair }) {
  return (
    // <div style={{ position: "relative" }}>
    //     <img src={imgUrl} className="iconImage" />
    // </div>

    <IconButtonContainer color={color} showImage={isHair}>
      {imgUrl && (
        <IconImage
          // className="iconImage"

          src={imgUrl || ""}
        />
      )}
    </IconButtonContainer>
  );
}
