import axios from "axios";

import environment from "./env.js";

const API = axios.create({
  baseURL: `${environment.fyndStylez0}`,
  headers: {
    "Content-Type": "application/json",
    Source: "web",
  },
});

export const setAuthorization = () => {
  API.defaults.headers["Authorization"] = "1234";
  //API.defaults.headers["X-Skip-Cors"] = true;
};

export const getModuleConfig = () => {
  return API.get("/model-config");
};

export const sendMeta = (requestId, tryOnType) => {
  return API.post("/meta", null, {
    headers: {
      "x-request-id": requestId,
      "x-try-on-type": tryOnType,
    },
  }).catch();
};

export const getSKUData = (id, apiKey) => {
  return API.get(`/tryon/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Error fetching SKU data:", error);
      throw error;
    });
};
export const getAllSkuData = () => {
  return API.get(`/tryon/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // console.error("Error fetching SKU data:", error);
      // throw error;
      return null;
    });
};
