export const dummyData = [
  {
    name: "lipstick",
    display: "Lipstick",
    tabs: [
      {
        name: "Matte",
        skus: [
          {
            id: "9278a5a6-65c8-4439-9e24-12345627ec50",
            color: "#460B0B",
          },
          {
            id: "bc591aaa-33ca-477e-ba9f-5c3770cd89f0",
            color: "#7E010E",
          },
          {
            id: "3fe8252a-72b9-4b91-ae84-c5cde1cf435a",
            color: "#0774CC",
          },
          {
            id: "95a6f2b7-7bec-4a1d-8c42-108ffc5f2717",
            color: "#771837",
          },
          {
            id: "da472d16-7e05-4cf2-a0b7-41860ad7ebee",
            color: "#420505",
          },
        ],
      },
      {
        name: "Gloss",
        skus: [
          {
            id: "6781651c-9973-4015-9c1a-a90ad88b0137",
            color: "#7F0652",
          },
          {
            id: "5f7e2517-7d0d-4c53-a4c9-9238252cddde",
            color: "#7F2929",
          },
          {
            id: "4d803ccd-5df1-47d1-86e7-c8a03499a032",
            color: "#FF0080",
          },
          {
            id: "dfbd9b55-903a-4dee-9d55-40145e6c9bb1",
            color: "#A80505",
          },
          {
            id: "55242383-77d7-452f-98ac-38178faca3b3",
            color: "#DF4994",
          },
        ],
      },
      {
        name: "Shimmer",
        skus: [
          {
            id: "d6389842-f3b5-4fa6-a4e7-03a300387be7",
            color: "#C61047",
          },
          {
            id: "38c3fba4-448b-405c-87ef-1e5aad9662d2",
            color: "#5F1111",
          },
          {
            id: "0cb93997-f567-4e49-9364-e920c22c8ad4",
            color: "#A30F43",
          },
          {
            id: "a9bc7079-8ca5-423a-9eae-018777389c31",
            color: "#39064B",
          },
          {
            id: "eb117fdc-fa2f-4e15-8e57-d4fc4691a1e1",
            color: "#C50287",
          },
        ],
      },
    ],
  },
  {
    name: "eyeshadow",
    display: "Eyeshadow",
    tabs: [
      {
        name: "matte",
        skus: [
          {
            id: "cf068ce4-2628-49e0-b095-61cedfe035f1",
            color: "#480505",
          },
          {
            id: "f59e9d94-1a5b-4f43-b7f1-a8568e32c839",
            color: "#832052",
          },
          {
            id: "ee41a819-07f7-4d58-b3fc-dafe738827e8",
            color: "#251204",
          },
          {
            id: "0bb10810-fd5a-4d74-9cfa-ce94cd55931f",
            color: "#670A99",
          },
          {
            id: "c2467b69-efe7-437f-8893-1e74dff5e9d8",
            color: "#910D42",
          },
        ],
      },
      {
        name: "Shimmer",
        skus: [
          {
            id: "847205cb-6e07-4dde-a7cf-35d42bdbcb02",
            color: "#480505",
          },
          {
            id: "75ee46ed-864c-4bf0-b4a2-2703b34ae521",
            color: "#832052",
          },
          {
            id: "5887d475-215b-4dc3-9cf3-7b12e70c3696",
            color: "#251204",
          },
          {
            id: "982b9a26-39f4-4997-b05f-375262150fba",
            color: "#670A99",
          },
          {
            id: "90f49eef-1b3a-4581-9225-fa3e957fc598",
            color: "#910D42",
          },
        ],
      },
    ],
  },
  {
    name: "blush",
    display: "Blush",
    tabs: [
      {
        name: "shimmer",
        skus: [
          {
            id: "aac75c08-ae5d-4f28-85b3-9cd2ce40337e",
            color: "#B34242",
          },
          {
            id: "5e8170ed-da3c-4b32-9b17-b1b42f0edc48",
            color: "#EC3273",
          },
          {
            id: "f13f90e5-d3c2-4089-b99f-5e505c02908e",
            color: "#83203E",
          },
          {
            id: "2c2c6a05-9949-4856-b933-10a6660aac88",
            color: "#861D52",
          },
          {
            id: "6e1860f4-b881-498f-a68b-0b8ce867ac08",
            color: "#8B0E33",
          },
        ],
      },
    ],
  },
  {
    name: "eyeliner",
    display: "Eyeliner",
    tabs: [
      {
        name: "matte",
        skus: [
          {
            id: "23461ea7-7e8c-4cb4-b341-703d60cd5160",
            color: "#042A0C",
          },
          {
            id: "3464d46b-b8fc-42d7-b626-c9723140f40c",
            color: "#300808",
          },
          {
            id: "492d7989-cfd1-41cb-bcdb-db2e4341488e",
            color: "#4E0405",
          },
          {
            id: "b1143af4-5745-431c-bb62-c06f1184ad99",
            color: "#2A5214",
          },
          {
            id: "342d0612-0ea3-4b1f-8213-cf78f731d0af",
            color: "#045671",
          },
        ],
      },
    ],
  },
  {
    name: "hair",
    display: "Hair Color",
    tabs: [
      {
        name: "glossy",
        skus: [
          {
            id: "6f5ff843-16e2-495f-884f-756b8366944c",
            color: "#3b3024",
          },
          {
            id: "87a2561c-a7f4-4190-8577-18273ec66972",
            color: "#ffd9b3",
          },
          {
            id: "90605a8d-79e6-4b86-9aaf-e7ece2b21727",
            color: "#9c5249",
          },
          {
            id: "f1a590cb-f9c5-40ff-b8a6-7dcf1043d161",
            color: "#ff66d9",
          },
          {
            id: "7da09c03-492f-4691-a159-f136214271ea",
            color: "#b55239",
          },
          {
            id: "d1e40a17-caf7-420b-8416-cbc507ebf874",
            color: "#33bbff",
          },
          {
            id: "9f6e27d9-9099-4e4d-9dc6-2191e02b6b35",
            color: "#ffd9cc",
          },
          {
            id: "12332487-a5c3-4498-a5c2-a513261b2dd5",
            color: "#7A2C25",
          },
          {
            id: "b00d531a-e4d8-4d33-abe2-6abd236190e9",
            color: "#e6ccb3",
          },
        ],
      },
    ],
  },
];
