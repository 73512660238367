export const GlamarSdkActions = {
  ShowBeautySdk: "SHOW_BEAUTY_SDK",
  HideBeautySdk: "HIDE_BEAUTY_SDK",
  ShowStyleSdk: "SHOW_STYLE_SDK",
  HideStyleSdk: "HIDE_STYLE_SDK",
  SetSelectedTabItem: "SET_SELECTED_TAB_ITEM",
  SetBeautySdkLoaded: "SET_BEAUTY_SDK_LOADED",
  SetStyleSdkLoaded: "SET_STYLE_SDK_LOADED",
  SetFaceCaptureScreenState: "SET_FACE_CAPTURE_SCREEN_STATE",
  SetUiSliderState: "SET_UI_SLIDER_STATE",
  SetFaceCaptureLoader: "SET_FACE_CAPTURE_LOADER",
  SetFaceCaptureElementState: "SET_FACE_CAPTURE_ELEMENTS_STATE",
  SetSizeRecievedState: "SET_SIZE_RECIEVED_STATE",
  SetSpectsSize: "SET_SPECTS_SIZE",
  SetFaceDataSuccess: "SET_FACE_DATA_SUCCESS",
  SetLensDiameter: "SET_LENS_DIAMETER",
  SetBridgeWidth: "SET_BRIDGE_WIDTH",
  SetDistanceRecognition: "SET_DISTANCE_RECOGNITION",
};

export const showBeautySdk = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.ShowBeautySdk,
    payload: data,
  });
};

export const hideBeautySdk = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.HideBeautySdk,
    payload: data,
  });
};

export const showStyleSdk = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.ShowStyleSdk,
    payload: data,
  });
};

export const hideStyleSdk = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.HideStyleSdk,
    payload: data,
  });
};

export const setSelectedTabItem = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetSelectedTabItem,
    payload: data,
  });
};

export const setBeautySdkLoaded = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetBeautySdkLoaded,
    payload: data,
  });
};

export const setStyleSdkLoaded = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetStyleSdkLoaded,
    payload: data,
  });
};
export const setFaceCaptureScreenState = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetFaceCaptureScreenState,
    payload: data,
  });
};
export const setUiSliderState = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetUiSliderState,
    payload: data,
  });
};

export const setFaceCaptureLoader = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetFaceCaptureLoader,
    payload: data,
  });
};

export const setFaceCaptureElementState = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetFaceCaptureElementState,
    payload: data,
  });
};
export const SetSpectsSize = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetSpectsSize,
    payload: data,
  });
};
export const setSizeRecievedState = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetSizeRecievedState,
    payload: data,
  });
};
export const setFaceDataSuccess = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetFaceDataSuccess,
    payload: data,
  });
};
export const setLensDiameter = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetLensDiameter,
    payload: data,
  });
};
export const setBridgeWidth = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetBridgeWidth,
    payload: data,
  });
};
export const setDistanceRecognition = (data, dispatch) => {
  dispatch({
    type: GlamarSdkActions.SetDistanceRecognition,
    payload: data,
  });
};
