import { GlamarSdkActions } from "../actions/actions";

const initialState = {
  isBeautySdk: true,
  isStyleSdk: false,
  selectedTabItem: null,
  isBeautySdkLoaded: false,
  isStyleSdkLoaded: false,
  isFaceCaptureScreenState: false,
  isUiSliderState: true,
  isFaceCaptureLoader: false,
  isFaceCaptureElements: true,
  isSizeRecieved: false,
  spectsSize: "",
  isFaceRecognationData: false,
  lensDiameter: 0,
  bridgeWidth: 0,
  distanceRecognition: "",
};

const sdkReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GlamarSdkActions.ShowBeautySdk:
      return {
        ...state,
        isBeautySdk: payload,
      };
    case GlamarSdkActions.HideBeautySdk:
      return {
        ...state,
        isBeautySdk: payload,
      };
    case GlamarSdkActions.ShowStyleSdk:
      return {
        ...state,
        isStyleSdk: payload,
      };
    case GlamarSdkActions.HideStyleSdk:
      return {
        ...state,
        isStyleSdk: payload,
      };
    case GlamarSdkActions.SetSelectedTabItem:
      return {
        ...state,
        selectedTabItem: payload,
      };
    case GlamarSdkActions.SetBeautySdkLoaded:
      return {
        ...state,
        isBeautySdkLoaded: payload,
      };
    case GlamarSdkActions.SetStyleSdkLoaded:
      return {
        ...state,
        isStyleSdkLoaded: payload,
      };

    case GlamarSdkActions.SetFaceCaptureScreenState:
      return {
        ...state,
        isFaceCaptureScreenState: payload,
      };

    case GlamarSdkActions.SetUiSliderState:
      return {
        ...state,
        isUiSliderState: payload,
      };

    case GlamarSdkActions.SetFaceCaptureLoader:
      return {
        ...state,
        isFaceCaptureLoader: payload,
      };
    case GlamarSdkActions.SetFaceCaptureElementState:
      return {
        ...state,
        isFaceCaptureElements: payload,
      };
    case GlamarSdkActions.SetSpectsSize:
      return {
        ...state,
        spectsSize: payload,
      };
    case GlamarSdkActions.SetSizeRecievedState:
      return {
        ...state,
        isSizeRecieved: payload,
      };
    case GlamarSdkActions.SetFaceDataSuccess:
      return {
        ...state,
        isFaceRecognationData: payload,
      };
    case GlamarSdkActions.SetLensDiameter:
      return {
        ...state,
        lensDiameter: payload,
      };
    case GlamarSdkActions.SetBridgeWidth:
      return {
        ...state,
        bridgeWidth: payload,
      };
    case GlamarSdkActions.SetDistanceRecognition:
      return {
        ...state,
        distanceRecognition: payload,
      };
    default:
      return state;
  }
};

export default sdkReducer;
