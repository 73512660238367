import TryOnSlider from "./Components/TryOnCarousel";
import "./App.css";
import { useEffect, useState } from "react";
import { dummyData } from "./dummyJsonData";
import { getAllSkuData, setAuthorization } from "./api";
import sendData, {
  sendDataBySku,
  sendWebDemoMessage,
} from "./Components/sendData";
import environment from "./env";

import styled from "styled-components";
import SizeCheck from "./Components/SizeCheck";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./redux/store";

import {
  setFaceCaptureScreenState,
  setUiSliderState,
  setFaceCaptureLoader,
  SetSpectsSize,
  setSizeRecievedState,
  setFaceDataSuccess,
  setLensDiameter,
  setBridgeWidth,
  setDistanceRecognition,
} from "./redux/actions/actions";
const SizeSelectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0%;
  top: 0%;
  background-color: transparent;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const UIWrapper = styled.div`
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  display: ${(props) => (props.show ? "flex" : "none")};
`;

function App() {
  const dispatch = useDispatch();
  const [
    isFaceCaptureScreenState,
    isUiSliderState,
    isFaceCaptureLoader,
    isFaceCaptureElements,
    spectsSize,
    isSizeRecieved,
  ] = useSelector((state) => [
    state.sdkTabsData.isFaceCaptureScreenState,
    state.sdkTabsData.isUiSliderState,
    state.sdkTabsData.isFaceCaptureLoader,
    state.sdkTabsData.isFaceCaptureElements,
    state.sdkTabsData.spectsSize,
    state.sdkTabsData.isSizeRecieved,
  ]);

  let catergoryObject = {};
  let categoryArray = [];
  let api_data;

  const [customData, setcustomData] = useState([]);
  let [gotApiResponse, setGotApiResponse] = useState(false);
  let [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const isWindowAvailable = () => {
    return typeof window !== "undefined";
  };

  // const isMobile = isWindowAvailable() && window.innerWidth < 10;

  function onRecognationRecieved(payload) {
    setFaceCaptureLoader(false, dispatch);

    setFaceDataSuccess(payload.success, dispatch);
    if (payload.success === false) {
      setFaceCaptureLoader(false, dispatch);
      setSizeRecievedState(true, dispatch);
    } else {
      SetSpectsSize(payload.frame_size, dispatch);
      setFaceCaptureLoader(false, dispatch);
      setSizeRecievedState(true, dispatch);
      setLensDiameter(payload.dimension.lens_diameter, dispatch);
      setBridgeWidth(payload.dimension.bridge_width, dispatch);
    }
  }

  async function createCustomData() {
    // setAuthorization();
    api_data = dummyData;

    // let response = await getAllSkuData();
    // if (!response) {
    //   api_data = dummyData;
    // } else {
    //   api_data = response.data;
    // }

    api_data.map((items) => {
      if (catergoryObject.hasOwnProperty(items.categoryName)) {
        catergoryObject[items.categoryName].push(items);
      } else {
        catergoryObject[items.categoryName] = [];
        catergoryObject[items.categoryName].push(items);
      }
    });

    Object.keys(catergoryObject).forEach((key) => {
      const newItem = {};

      newItem.id = key;

      newItem.data = catergoryObject[key];
      categoryArray.push(newItem);
    });

    setcustomData(categoryArray);

    setGotApiResponse(true);
  }

  useEffect(() => {
    setcustomData(dummyData);
    window.GlamAR.init("sdk-iframe", "62a16d1e-0103-4b73-9a07-57b55aeb6277", {
      mode: "private",
      platform: "web",
    });
    window.GlamAR.addEventListener("*", (e) => {
      switch (e) {
        case "loaded":



          window.GlamAR.loadDemo(225);
          window.GlamAR.applyMakeupBySku(dummyData[0]?.tabs[0]?.skus[0]?.id);

          break;
        case "init-complete":
          break;
        case "sku-applied":

          break;
        case "sku-failed":

          break;
        default:
          console.log("na");
      }
    });
  }, []);

  return (
    <>
      <SdkFrame />
      {/* <div className="App"> */}

      <UIWrapper show={isUiSliderState}>
        <TryOnSlider
          api_data={customData}
          is_sdk_loaded={isSdkLoaded}
          got_api_response={gotApiResponse}
        />
      </UIWrapper>

      {/* <SizeSelectionWrapper show={isFaceCaptureScreenState}>
        <SizeCheck />
      </SizeSelectionWrapper> */}
      {/* </div> */}
    </>
  );
}

function SdkFrame() {
  return (
    <>
      <div id="sdk-iframe"></div>
    </>
  );
}

export default App;
