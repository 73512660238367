import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState, useRef } from "react";
import TypeButton from "./TypeButton";
import styled from "styled-components";
import IconButton from "./IconButton";

const TypeButtonContainer = styled.div`
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-left: 100px;
`;

export default function TryOnIcons(props) {
  let slickSliderRef = useRef();
  const [currentIconIndex, setCurrentIconIndex] = useState(0);
  const [showCameraButton, setshowCameraButton] = useState(true);

  let handleBeforeIconChange = (current) => {
    setshowCameraButton(false);
  };

  let handleTryOnIconChange = (current) => {
    setCurrentIconIndex(current);
    let sku =
      props.api_data[props.stateValue]?.tabs?.[props.currentType]?.skus[current]
        .id;

    window.GlamAR.applyMakeupBySku(sku);
  };

  function afterTryOnItemStateChange() {
    if (currentIconIndex === 0) {
      let sku =
        props.api_data[props.stateValue]?.tabs?.[props.currentType]?.skus[0].id;

      window.GlamAR.applyMakeupBySku(sku);
    } else {
      slickSliderRef.current.slickGoTo(0, false);
      handleTryOnIconChange(0);
      setCurrentIconIndex(0);
    }
  }

  const settings = {
    variableWidth: true,
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 150,
    arrows: false,
    cssEase: "linear",
    swipeToSlide: true,
    touchThreshold: 20,
    beforeChange: handleBeforeIconChange,
    afterChange: handleTryOnIconChange,
  };

  useEffect(() => {
    afterTryOnItemStateChange();
  }, [props.stateValue, props.currentType]);

  function handleTypeButtonClick(index) {
    props.setcurrentType(index);
  }

  const renderTypeButtons = () => {
    return props.api_data[props.stateValue]?.tabs.map((type, index) => (
      <TypeButton
        typeName={type.name}
        key={index}
        setIconsOrColors={() => handleTypeButtonClick(index)}
        currentButton={props.currentType}
        keyValue={index}
      />
    ));
  };

  return (
    <>
      <div id="icon-slider-div">
        <img
          className="icon-center-dash"
          src="./images/rectangle.png"
          alt="rectangle"
        />

        <div className="icon-blur-background"></div>


        <Slider {...settings} ref={slickSliderRef}>
          {props.api_data?.[props.stateValue]?.tabs[props.currentType].skus.map(
            (sku, index) => (
              <IconButton
                key={index}
                color={sku.color}
                isHair={
                  props.api_data[props.stateValue].name === "hair"
                    ? true
                    : false
                }
              />
            )
          )}
        </Slider>
      </div>
      <TypeButtonContainer>{renderTypeButtons()}</TypeButtonContainer>
    </>
  );
}
// }
